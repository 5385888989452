<template>
  <el-cascader
    v-model="value"
    placeholder="请选择地区"
    clearable
    filterable
    :props="props"
    @change="handleChangeArea"
  ></el-cascader>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'AreaSelect',
    components: {},
    props: {
      // 默认显示2级联动
      showLevel: {
        type: [Number, String],
        default: 2,
      },
    },
    data() {
      return {
        value: '',
        props: {
          value: 'id',
          label: 'title',
          emitPath: false,
          checkStrictly: true,
          lazy: true,
          lazyLoad: this.loadSelectList,
        },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      async loadSelectList(node, resolve) {
        let { data } = await postAction('/baseAdmin/common/common-area', {
          parent_id: node.value,
        })
        data.forEach((i) => {
          i.leaf = node.level >= this.showLevel - 1
        })
        resolve(data)
      },
      handleChangeArea(v) {
        this.$emit('changeArea', v)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
